import * as React from 'react'
import Layout from '../components/layout'


import { graphql } from "gatsby"


import CardWork from "../components/cardWork";

const WorkPage = ({ data }) => {
  const workData = data.allPrismicPost.edges
   //console.log(workData) 
  const works = []
  //const image = getImage(post.data.cover)
  //const tags = post.data.categories
  for (let i = 0; i < workData.length; i++) {
    works.push(workData[i].node)
  }
  //console.log(works) 
  return (
    <Layout pageTitle="work">
      <h2>Selected work</h2>
      <p>Here are some examples of my work. You can also find some more at <a className="link" href="https://www.behance.net/may-valdez" rel="noreferrer" target="_blank">Behance</a> and <a className="link" href="https://dribbble.com/may_vdz" rel="noreferrer" target="_blank">Dribble</a>.</p>
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
        { 
          works.map((work, index) =>{
            return <CardWork 
              key={index}
              title={work.data.title.text}
              imagen={work.data.cover}
              tags={work.data.categories}
              url={work.uid}
              info={work.data.description.text}
            />
          }) 
        }
        
        
      </div>


    </Layout>
  )
}

export default WorkPage


export const pageQuery = graphql`
  query MyQuery {
    allPrismicPost {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            description {
              text
            }
            cover {
              gatsbyImageData
            }
            categories {
              category {
                document {
                  ... on PrismicCategory {
                    data {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
