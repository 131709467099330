import React from 'react';

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'

import { useState } from 'react';

import {
    cardWork,
    infoCard,
    cardWorkText
} from './cardWork.module.css'


function CardWork({ imagen, title, info, tags, url }) {
   
    const imgCard = getImage(imagen)
    const tagsCard = tags
    const categoriesPost = []

    for (let i = 0; i < tagsCard.length; i++) {
        categoriesPost.push(tagsCard[i].category.document.data.name)
    }
   // State for activate Mail animation 
   const [cardActive, setCardActive] = useState(false);

   function activeCard(e) {
    setCardActive(true)
    }
    function nonActiveCard(e) {
        setCardActive(false)
    }
   
    return (
        <div className={cardWork}>
            <Link onMouseOver={(e)=>activeCard(e)} onMouseLeave={(e)=>nonActiveCard(e)} to={`/work/${url}`}>
                <div style={{ display: "grid" }}>
                   <GatsbyImage
                    
                    style={{
                    gridArea: "1/1", 
                    borderRadius: 3,
                    }}
                    alt={title}
                    image={imgCard}
                    placeholder="blurred"
                    layout="fullWidth"
                    />  
                    <div
                    style={{
                    gridArea: "1/1",
                    borderRadius: 3,
                    position: "relative",
                    placeItems: "center",
                    display: cardActive ? "grid" : "none"  ,
                    backgroundColor: "rgba(194, 129, 209, 0.6)",
                    }}
                    >
                        
                        
                        <p className="titleHover">{title}</p>

                    </div>

                </div>
                
            </Link>
            <div className={cardWorkText} style={{color: cardActive ? "var(--violet3)" : "" }}>
                
                <h4 style={{color: cardActive ? "var(--violet3)" : "" }}>{title}</h4>
                <p className={infoCard}>{info}</p>
                <div className="flexH"  >
                    {
                        categoriesPost.map((category, index) =>{
                        return <p key={index} className="tag">{category}</p>
                        })
                    }
                </div>
            </div>
             
        </div>
        
    )
       
}

export default CardWork;